import React, { useEffect, useState } from "react";
import profileImage from "./assets/images/profile.png";
import {
  FaInstagram,
  FaPhone,
  FaClock,
  FaMapMarkerAlt,
  FaChevronUp,
  FaChevronDown,
} from "react-icons/fa"; // Import new icons
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "./redux/apiSlice";
import { fetchProducts } from "./redux/productSlice";
import "./Loader.css";

const Home = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryProducts, setCategoryProducts] = useState([]); // State for products
  const [loadingProducts, setLoadingProducts] = useState(false);

  const [companyData, setCompanyData] = useState(null); // State for company data

  // Fetch company data
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/company`
        );
        const data = await response.json();
        if (data.success) {
          setCompanyData(data.companies[0]); // Assuming you're using the first company
        } else {
          console.error("Failed to fetch company data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    fetchCompanyData();
  }, []);

  const handleCategoryClick = async (category) => {
    if (selectedCategory === category) {
      // Collapse category
      setSelectedCategory(null);
      setCategoryProducts([]);
      return;
    }

    setSelectedCategory(category); // Set selected category
    setLoadingProducts(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/sectors/${category._id}/products`
      );

      const data = await response.json();
      if (data.success) {
        setCategoryProducts(data.products); // Store fetched products
      } else {
        console.error("Failed to fetch products:", data.message);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoadingProducts(false);
    }
  };

  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);

  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    if (!categories || categories.length === 0) {
      // Fetch categories if not already loaded
      dispatch(fetchCategories());
    }
    if (!products || products.length === 0) {
      dispatch(fetchProducts());
    }
  }, [dispatch, categories, products]);

  return (
    <div
      style={{
        backgroundColor: "#F5F7F8",
        height: "100%",
        marginBottom: "10px",
      }}
    >
      <header style={{ position: "relative", textAlign: "center" }}>
        <img
          src={
            companyData?.banner
              ? `${process.env.REACT_APP_BACKEND_URL}/uploads/${companyData.banner}`
              : profileImage
          }
          alt="Restaurant Banner"
          style={{
            width: "100%",
            height: "200px",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <img
            src={
              companyData?.image
                ? `${process.env.REACT_APP_BACKEND_URL}/uploads/${companyData.image}`
                : profileImage
            }
            alt="Company Logo"
            style={{
              width: "200px",
              height: "200px",
              border: "5px solid #ddd",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        <div style={{ marginTop: "150px", zIndex: 1 }}>
          <h1 style={{ margin: "10px 0" }}>
            {companyData?.name || "Happy Belly"}
          </h1>
          <h5>
            {companyData?.title ||
              '"We may not be the best, but there\'s no one better than us."'}
          </h5>
          <div style={{ backgroundColor: "#F5F7F8", width: "100%" }}>
            <div style={styles.cardsContainer}>
              <Card
                icon={<FaInstagram />}
                link={
                  companyData?.instagram ||
                  "https://www.instagram.com/happybellly/?hl=en"
                }
                title="Instagram"
              />
              <Card
                icon={<FaPhone />}
                link={`tel:${companyData?.phone || "+355 69 54 50 082"}`}
                title="Call"
              />
              <div style={styles.card}>
                <div style={styles.cardHeader}>
                  <FaClock />
                </div>
                <div style={{ ...styles.cardLink, fontSize: "12px" }}>
                  {companyData?.orariStart || "10:00"} -{" "}
                  {companyData?.orariEnd || "22:00"}
                </div>
              </div>
              <Card
                icon={<FaMapMarkerAlt />}
                link={
                  companyData?.location ||
                  "https://www.google.com/maps/place/Happy+Belly+%2FEat+Smart,+Rruga+Ibrahim+Rugova,+Tirana+1001/data=!4m2!3m1!1s0x1350310358d4105d:0x1f0e494425554099?utm_source=mstt_1&entry=gps&g_ep=CAESCTExLjQ5LjcwMRgAIP___________wEqAA%3D%3D"
                }
                title="Location"
              />
            </div>
          </div>
        </div>
      </header>

      <nav style={styles.nav}>
        {categories?.sectors
          ?.slice() // Create a shallow copy of the array
          .sort((a, b) => a.nrRendor - b.nrRendor) // Sort categories by nrRendor
          .map((category) => (
            <div key={category.name} style={styles.category}>
              <div
                onClick={() => handleCategoryClick(category)}
                style={styles.button}
              >
                <div style={styles.titleDivCategory}>
                  <div>
                    <img
                      src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${category.image}`}
                      alt={category.name}
                      style={styles.categoryImage}
                    />
                  </div>
                  <div style={styles.categoryName}>{category.name}</div>
                </div>
                <div style={styles.categoryArrow}>
                  {selectedCategory?.name === category.name ? (
                    <FaChevronUp />
                  ) : (
                    <FaChevronDown />
                  )}
                </div>
              </div>

              {selectedCategory?.name === category.name && (
                <div style={styles.dropdown}>
                  {loadingProducts ? (
                    <div style={styles.loaderContainer}>
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    </div>
                  ) : categoryProducts.length > 0 ? (
                    categoryProducts.map((product) => (
                      <div key={product._id} style={styles.productCard}>
                        <img
                          src={`${process.env.REACT_APP_BACKEND_URL}/uploads/${product.image}`}
                          alt={product.name}
                          style={styles.productImage}
                        />
                        <h3>{product.name}</h3>
                        <p>{product.description}</p>
                        <p>{product.price ? `${product.price} Lek` : null}</p>
                      </div>
                    ))
                  ) : (
                    <p>No products available for this category.</p>
                  )}
                </div>
              )}
            </div>
          ))}
      </nav>
    </div>
  );
};

// Card component for social media and contact links
const Card = ({ icon, link, title }) => (
  <a href={link} style={{ ...styles.card, ...styles.cardLink }}>
    <div style={styles.cardHeader}>{icon}</div>
    <div>{title}</div>
  </a>
);

// Styles object for responsive design
const styles = {
  nav: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  categoryImage: {
    width: "70px",
    height: "70px",
    objectFit: "cover",
    borderRadius: "5px",
  },
  categoryName: {
    marginLeft: "20px",
    fontSize: "18px",
    fontWeight: 500,
  },
  categoryArrow: {
    alignSelf: "center",
  },
  category: {
    width: "90%",
    borderRadius: "20px",
    textAlign: "center",
    justifyContent: "space-between",
  },
  button: {
    borderRadius: "20px",
    padding: "10px 20px",
    backgroundColor: "#fff",
    border: "1px solid #ddd",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
  },
  dropdown: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    marginTop: "10px",
  },
  productCard: {
    width: "90%",
    borderRadius: "10px",
    border: "1px solid #ddd",
    padding: "10px",
    textAlign: "center",
  },
  productImage: {
    width: "100%",
    height: "250px",
    objectFit: "contain",
  },
  titleDivCategory: {
    display: "flex",
    height: "100%",
  },
  cardsContainer: {
    border: "1px solid #ddd",
    borderRadius: "10px",
    width: "95%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    margin: "20px auto",
    backgroundColor: "#fff",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    borderRadius: "5px",
    textAlign: "center",
  },
  cardHeader: {
    fontSize: "30px",
    marginBottom: "5px",
  },
  cardLink: {
    marginTop: "5px",
    textDecoration: "none",
    color: "#000",
  },

  "@media (max-width: 768px)": {
    nav: {
      flexDirection: "column",
      alignItems: "center",
    },
    dropdown: {
      alignItems: "center",
    },
    productCard: {
      width: "90%",
    },
    cardsContainer: {
      width: "90%",
      flexDirection: "column",
      alignItems: "center",
    },
  },
};

export default Home;
